:root {
  --main-bg: #ffffff;
  --second-bg: #fafafb;
  --txt-color: #455560;
  --txt-white: #fff;
  --primary-main-color: #318972;
  --primary-main-color-hex: 49, 137, 114;
  --primary-light-color: #3c44b126;
  --secondary-main-color: rgb(52, 58, 64);
  --secondary-light-color: rgba(52, 58, 64, 0.8);
  --box-shadow: rgba(18, 24, 31, 0.2) 0px 8px 24px;

  --main-bg-light: #ffffff;
  --second-bg-light: #fafafb;
  --txt-color-light: #455560;
  --box-shadow-light: rgba(149, 157, 165, 0.2) 0px 8px 24px;

  --main-bg-dark: #2d2d2d;
  --second-bg-dark: #202020;
  --txt-color-dark: #bbbbbb;
  --box-shadow-dark: rgba(0, 0, 0, 0.2) 0px 5px 10px;

  --sidebar-width: 270px;
  --border-radius: 15px;
  --topnav-height: 70px;
  --transition-cubic: cubic-bezier(0.175, 0.885, 0.32, 1.275);
  --table-hover: #f83245;
}
/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
} */
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
*,
html,
body {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
