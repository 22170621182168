@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;500&display=swap");
.contact {
  background-color: rgba(var(--primary-main-color-hex), 0.4);
  /* background-image: url(https://preview.colorlib.com/theme/glint/images/xcontact-bg.jpg.pagespeed.ic.Yg-_T7iMmI.webp); */
  background-repeat: no-repeat;
  background-position: center, center;
  padding-top: 4rem;
  padding-bottom: 4rem;
  position: relative;
}

@media only screen and (max-width: 600px) {
  .row {
    width: auto;
    padding-left: 25px;
    padding-right: 25px;
  }
}
@media only screen and (max-width: 800px) {
  .row {
    width: 90%;
  }
}
.row {
  width: 94%;
  max-width: 1200px;
  margin: 0 auto;
}
@media only screen and (max-width: 600px) {
  .contact__section {
    padding-bottom: 3rem;
    text-align: center;
  }
}
@media only screen and (max-width: 1000px) {
  .contact__section {
    max-width: 700px;
  }
}
@media only screen and (max-width: 1200px) {
  .contact__section {
    max-width: 900px;
  }
}

.contact__section {
  max-width: 1000px;
  background-color: rgba(var(--primary-main-color-hex), 0.8);
  color: rgba(255, 255, 255, 0.4);
  font-size: 15px;
  line-height: 30px;
  position: relative;
  display: flex;
}
.contact__primary {
  width: 65%;
  padding: 0 5rem 3rem;
}

.contact__secondary {
  padding: 0 4rem;
  width: 35%;
  background-color: var(--primary-main-color);
}
@media only screen and (max-width: 1000px) {
  .contact__section {
    flex-direction: column;
  }
  .contact__primary,
  .contact__secondary {
    width: auto;
    flex-grow: 1;
    padding: 0 4rem 3rem;
  }
}
@media only screen and (max-width: 600px) {
  .contact__primary,
  .contact__secondary {
    padding: 0 20px 3rem;
  }
}
@media only screen and (max-width: 400px) {
  .contact__primary,
  .contact__secondary {
    padding: 0 5px 3rem;
  }
}

@media only screen and (max-width: 800px) {
  .contact__section h3 {
    text-align: center;
  }
}
.contact__section h3 {
  margin-top: 0;
  margin-bottom: 6.6rem;
  color: #fff;
}

.h6 {
  font-size: 1.3rem;
  line-height: 1.385;
  text-transform: uppercase;
  letter-spacing: 0.16rem;
}
@media only screen and (max-width: 600px) {
  .h6 {
    margin-top: 3.6rem;
    margin-bottom: 0.9rem;
  }
}

.h6 {
  margin-top: 4.2rem;
  margin-bottom: 1.5rem;
  color: var(--txt-white);
  font-family: "montserrat", sans-serif;
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}
.contact__primary .contactForm {
  margin-top: -1.5rem;
}

.cInfo {
  margin-bottom: 30px;
}
@media only screen and (max-width: 600px) {
.cInfo {
    margin-bottom: 10px;
  }
}
.cInfo p {
  margin: 0;
  font-family: "Montserrat", sans-serif;
  font-weight: 300;
  color: var(--txt-white);
  padding: 0;
}
h5 {
  margin-top: 0;
  color: var(--txt-white);
  text-transform: capitalize;
  font-size: 16px;
  line-height: 1.313;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  margin-bottom: 10px;
}

@media only screen and (max-width: 600px) {
  h5 {
    margin-top: 3.6rem;
    margin-bottom: 0.9rem;
  }
}
.contact__section input[type="email"],
.contact__section input[type="number"],
.contact__section input[type="text"],
.contact__section textarea {
  color: var(--txt-white);
  border-bottom: 1px solid var(--txt-white);
  margin-bottom: 0.9rem rem;
}

input[type="email"],
input[type="number"],
input[type="text"],
textarea {
  font-family: "Poppins", sans-serif;
  display: block;
  border: 0;
  outline: none;
  line-height: 3rem;
  max-width: 100%;
  background: transparent;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  color: var(--txt-white);
}
input::placeholder,
textarea::placeholder {
  color: var(--txt-white);
  text-transform: capitalize;
}
.full__width {
  width: 100%;
}
textarea {
  padding-top: 10px;
  line-height: normal;
  overflow: auto !important;
}
.contact__section button {
  margin-top: 3.6rem;
  background: var(--txt-white);
  border: none !important;
  color: var(--primary-main-color);
  font-family: "montserrat", sans-serif;
  font-size: 1.2rem;
  text-transform: uppercase;
  letter-spacing: 0.3rem;
  line-height: 4rem;
  padding: 0;
  text-align: center;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  outline: none !important;
}

.contact__section button.full__width {
  width: 100%;
  margin-right: 0;
}
.social__links {
  list-style: none;
  display: inline-block;
  margin: 0;
  font-size: 2rem;
}
.social__links li {
  margin-right: 15px;
  padding-left: 0;
  display: inline-block;
  cursor: pointer;
}
.social__links li a {
  color: var(--txt-white);
  font-size: 25px;
}
